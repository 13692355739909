export const  messages = {
    en: {
        message: {
            //owner
            //home
            home_page :'Home Page',
            sell_product:'Sell Products',
            sell_report:'Sell Report',
            inventory:'Inventory',
            financial_report:'Financial Report',
            general_settings:'General Settings',
            inventory_control:'Inventory Control',
            setting:'Setting',
            warehouse:'Ware House',
            online_orders:'Online Orders',

            //Sell Products
            barcode:'Barcode',
            itemCode:'Item Code',
            itemName:'Item Name',
            search_by_barcode:'Search by barcode',
            stock:'Stock',
            selected_items:'Selected Items',
            submit_sales:'Submit Sales',
            sale_setup:'Sale Setup',
            discount:'Discount',
            by_price:'By Price',
            by_rate:'By Rate',
            reset:'Reset',
            enter_discount_ks:'Enter discount (Ks)',
            enter_discount_rate:'Enter discount ( 1-100%)',
            enter_pay_amount:'Enter pay amount',
            please_choose_payment:'Please choose payment method',
            pay_amount:'Pay amount',
            add:'Add',
            customers:'Customers',
            //voucher
            list_view:'List Views',
            table_view:'Table Views',
            voucher_no:'Voucher No',
            items:'Items',
            qty:'Qty',
            price:'Price',
            amount:'Amount',
            total:'Total',
            tax:'Tax',
            net_amount:'Net Amount',
            cash_received:'Received',
            change:'Change',
            credit:'Credit',
            sell_now:'Sell now',
            thank:'Thank You',
            restrict_refund:'Refund does not allow',
            //end sell products

            //start sell report
            invoices:'Invoices',
            daily:'Daily',
            monthly:'Monthly',
            yearly:'Yearly',
            first_added:'Added',
            in_shop_sell:'In Shop Sell',
            online_sell:'Online Sell',
            live_sell:'Live Sell',
            info_update:'Info Update',
            stock_update:'Stock Update',
            waste_update:'Waste',
            export:'Export',
            sell_type:'Sell Type',
            customer_name:'Customer Name',
            sold_items:'Sold Items',
            control:'Control',
            count:'Count',
            details:'Details',
            enter_vocher_id:'Enter Voucher Id',
            //end sell report

            //start inventory
            product_management:'Products Management',
            categories_management:'Categories Management',
            live_management:'Live Sale Management',
            promotion_management:'Promotion Management',
            delivery_management:'Delivery Management',
            payment_management:'Payment Management',
            cashier_management:'Cashier Management',
            table_management:'Table Management',
            tables:'Tables',
            creat_new_table:'Create New Table',
            //end inventory
            //product create
            create_product:'Add New Product',
            expire_date:'Expire Date',
            //end product create

            //products management
            products:'Products',
            item_code:'Item Code',
            photo:'Photo',
            type:'Type',
            buy_price:'Buy Price',
            sell_price:'Sell Price',
            kitchen:'Kitchen',
            brand:'Brand',
            low_stock_products:'Low Stock Products',
            expire_list:'Expire Lists',
            show_stock_customer:'Show Stock to Customers',

            //end products management

            //category management
            category:'Category',
            create_category:'Create New Category',
            upload_image:'Upload Image',
            category_name:'Category name',
            category_lists:'Category Lists',
            cate_already_exist:'Category name is already added.',
            update_category:'Update Category',
            delete_confirm:'Are you sure to delete?',
            deleted:'Deleted.',
            updated:'Updated',
            category_delete_confirm:'This is category is used by your products. Are you sure to delete?',
            category_update_confirm:'This is category is used by your products. Are you sure to update?',
            enter_category:'Enter Category Name',
            //end category management

            //voucher details
            voucher_details:'Voucher Details',
            customer_info:'Customer Info',
            total_credit:'Total Credit',
            print:'Print',
            note:'',
            payment_history:'Payment History',
            select_payment_amount:'Please select payment method and fill amount',
            refund:'Refund',
            refund_manage:'Manage Refund Items',
            //end voucher details

            //product detail
            product_details:'Product Details',
            more_options:'More Options',
            edit:'Edit',
            add_stock:'Add Stock',
            add_relation:'Add Relation',
            waste:'Waste',
            product_history:'Product History',
            sellPrice:'Sell Price',
            delete:'Delete',

            weight:'Weight',
            add_discount:'Add Discount (1-100%)',
            unit:'Unit',
            relations_table:'Relation Tables',
            product_delete_warning:'Are you sure to delete? Records of this product will be permanently deleted.',
            purchase_lists:'Purchase Lists',
            //end product detail

            //branches
            branches:'Branches',



            //user
            //home

            welcome: 'Welcome To',
            welcome2:'Explore Our Shopping Mall',
            nav_home:'Home',
            nav_about:'About',
            nav_contact:'Contact',
            nav_signIn:'Sign in',
            search_cities:'Search Cities',

            //Shop
            shop_page_title:'Current City',
            shop_page_choose:'Choose City',
            shop_page_categories:'Categories',
            shop_page_showAll:'Show All',
            shop_page_trending:'Trending Shops',
            shop_page_promotion:'Promotion Shops',
            shop_page_recommend:'Recommended Shops',

            //All shops, shop info, card
            search:'search',
            shop_profile:'Profile',
            shop_products:'Products',
            all:'All',
            seeMore:'See More',
            add_to_cart:'Add To Cart',
            description:'Description',
            size:'Size',
            color:'Color',
            out_of_stock:'Out Of Stock',
            item_available:'Items Available',
            best_seller:'Best Seller Items',


            //Usr Profile
            logout:'Logout',
            update:'Update',
            profile:'Profile',
            orders:'Orders',
            live_orders:'Live Orders',
            view_details:'View Details',
            pending:'Pending',
            confirm:'Confirm',
            confirmed:'Confirmed',
            paid:"Paid",
            cancel:'Cancel',
            canceled:'Canceled',
            order_id:'Order ID',
            total_price:'Total Price',
            phone:'Phone',
            email:'Email',
            name:'Name',
            password:'Password',
            address:'Address',
            signin:'Sing in',
            register:'Register',
            continue_with:'Continue with',

            order_details:'Order Details',
            date:'Date',
            delivery_method:'Delivery Methods',
            payment_method:'Payment Methods',
            choose_payment:'Choose Payment Methods',
            choose_delivery:'Choose Deliver Methods',
            account_no:'Account No',
            upload_transaction:'Upload Transaction Photo',
            send:'Send',
            select_city:'Select City',
            other:'Other',
            save:'Save',

            //refund
            available:'Available',
            refund_count:'Refund Count',


            //Cart
            cart_items:'Your Items',
            cost:'Cost',
            update_again:'Update Again?',
            update_info:'Update Your Information!',
            update_info_warning:'Please update your Address!',
            next:'Next',
            these_rur_info:'These are your current address?',
            promo_code:'Promotion Code',
            previous:'Previous',
            order_now:'Order Now',
            check:'Check',
            check_order:'Check Order',
            order_success:'Congratulations!! Your order is successfully arrived to shop owner.',
            sry_cant_promo:'Sorry!you can not use this promo code!',
            sry_purchase_least:'Sorry!You have to purchase at least',
            sry_code_expire:"Sorry!Your Code is expired.",
            sry_code_already:"Sorry your account has been used the promotion code!",
            sry_promo_limit:"Sorry the promotion limit is fulled!",

            //Live Cart
            message:'Message',
            live_order_cant:"Sorry, you can't order now! Please try again in next session.",
            buy_now:'Buy Now',
            live_sale_product:'Live Sale Products',

            //Dialog,
            yes:'Yes',
            no:'No',
            close:'Close',

            //Shop Card warning
            already_selected:'Already Selected',
            do_u_remove_item:'Do you want to remove items from previous shop?',

            //    login
            hello:"Hello Again!",
            wct_flash:'Welcome To Flash Mall',
            mail_already:'Mail already in used!',
            enter_phone:'Enter your Phone number',
            get_otp:'GET OTP',
            enter_code:'Enter your OTP code',
            verify:'Verify',
            resend:'Resend OTP',
            not_register:"You haven't register with this mail.",
            otp_sen:'OTP has been sent to your phone via SMS.Please wait for a moment',
            invalid_phone:'Invalid Phone Number Format !',
            invalid_code:'Invalid Verification Code!',
            invalid_otp:'Invalid OTP Format !',
            veri_success:'Verification Success',
            fill_name:'Please Fill user name!',
            fill_email:'Please Fill email!',
            fill_address:'Please Fill address!',


            apartment_info: 'Apartment Information',
            apartments: "Apartments",
            reservation: "Reservation",
            table_count:'Table Count',
            create_booking:'Create Booking',
            table_reservation:'Table Booking',
            serve_now: "Serve Now",
            reject:'reject',
            booking_alert:'Booking Alert',
            status:'Status',
            booking_date:'Booking Date',
            current_staying:'Current Staying',
            create_apartment:'Create Apartment',

            check_booking: "Check Booking",
            update_reservation: "Update Reservation",
            create_reservation:"Create Reservation",
            reservation_histories: "Reservation Histories",
            extra_bed_fee:"Extra Bed Fee",
            room_price:"Room Price",
            reserved: "Reserved",
            reserve:"Reserve",
            check_in:"Check in",
            checkout:"Check out",
            checkin_detail:"Checkin Detail",
            menu:'Menu',
            extra_bed:'Extra bed',
            clear_all:'Clear All',
            ordered_foods:'Ordered Foods',
            reserved_dates:'Reserved Dates',
            guest_info:'Guest Info',
            reservation_list:'Reservation List',
            start_date:'Start Date',
            end_date:'End Date',
            create_ticket:'Ticket',
            create_party:'Party Ticket',
            swimming_pool_report:'Swimming Pool Report',
            ticket_id:'Ticket ID',
            people_count:'People Count',

            select_date_by_order:"Please Select date in order",
            create_new_aprtment:"Create New Apartment",
            family_count:"Family Count",
            room_type:"Room Type",
            room:"Room",
            adult_count:'Adult Count',
            infant_count:'Infant Count',
            paid_amount:'Paid Amount',
            reserved_date:'Reserved Date',
            sure_checkin:'Are you sure to check in?',

            staffs_management:"Staffs Management",
            staffs:"Staffs",
            add_staff:"Add Staff",
            department:'Department',
            dob:'Date of  birth',
            basic_salary:'Basice Salary',
            take_attendance:'Takce Attendance',
            staff_attendance:'Staff Attendance',
            start_time:'Start Time',
            end_time:'End Time',
            working_hours:"Working Hours",
            staff_id:"Staff ID",

            //swimming pool category
            swimming_management: "Swimming Pool Category Management",
            swimming_pool_category_list: "Swimming Pool Category List",
            swimming_pool_category: "Swimming Pool Category ",
            swimming_pool_party_category: "Swimming Pool Party Category ",

        }
    },
    mmr: {
        message: {
            //swimming pool category
            swimming_management: "ရေကူးကန် စီမံခန့်ခွဲမှု",
            swimming_pool_category_list: "ရေကူးကန် အမျိုးအစားစာရင်း",
            swimming_pool_category: "ရေကူးကန် အမျိုးအစား",
            swimming_pool_party_category: "ရေကူးကန် ပါတီအမျိုးအစား",



            //owner
            //home
            home_page :'ပင်မစာမျက်နာ',
            sell_product:'အရောင်းစာမျက်နာ',
            sell_report:'အရောင်းမှတ်တမ်း',
            inventory:'ကုန်ပစ္စည်းစာရင်း',
            financial_report:'ငွေကြေးမှတ်တမ်း',
            general_settings:'အထွေထွေ စက်တင်များ',
            inventory_control:'ကုန်ပစ္စည်းစာရင်း ထိန်းချုပ်မှု',
            setting:'စက်တင်',
            warehouse:'သိုလှောင်ရုံ',
            online_orders:'မှာယူမှုများ',

            //Sell Products
            barcode:'ဘားကုဒ်',
            itemCode:'ကုဒ်နံပါတ်',
            itemName:'ပစ္စည်းအမည်',
            search_by_barcode:'ဘားကုဒ်ဖြင့်ရှာရန်',
            stock:'အရေအတွက်',
            selected_items:'ရွေးချယ်ထားသော ပစ္စည်းများ',
            submit_sales:'အရောင်းအပြင်အဆင်',
            sale_setup:'အရောင်းအပြင်အဆင်',
            discount:'လျှော့စျေး',
            by_price:'ပမာဏဖြင့်',
            by_rate:'ရာခိုင်နှုန်းဖြင့်',
            reset:'ပြန်ပြင်မည်',
            enter_discount_ks:'လျှော့စျေး(ကျပ်) ထည့်ပါ',
            enter_discount_rate:'လျှော့စျေးနှုန်း(1-100%) ထည့်ပါ',
            enter_pay_amount:'ရရှိငွေထည့်ပါ',
            please_choose_payment:'ငွေပေးချေမှုနည်းလမ်း ရွေးပါ',
            please_add_customer:'အကြွေးမှတ်ရန် ဝယ်ယူသူ ရွေးပါ',
            discount_greater_total:'လျှော့ဈေးသညါ စုစုပေါင်းတန်ဖိုးထက် များနေသညါ။',
            pay_amount:'ရရှိသောငွေပမာဏ',
            add:'ထည့်ပါ',
            customers:'ဖောက်သည်များ',
            //voucher
            list_view:'ပုံမှန်ဘောင်ချာ',
            table_view:'ဇယားဘောင်ချာ',
            voucher_no:'ဘောင်ချာအမှတ်',
            items:'ပစ္စည်းများ',
            qty:'အရေအတွက်',
            price:'ဈေးနှုန်း',
            amount:'တန်ဖိုး',
            total:'စုစုပေါင်း',
            tax:'အခွန်',
            net_amount:'ကျသင့်ငွေ',
            cash_received:'လက်ခံ',
            change:'ပြန်အမ်းငွေ',
            credit:'ကျန်ငွေ',
            sell_now:'ရောင်းမည်',
            thank:'ကျေးဇူးတင်ပါတယ်',
            restrict_refund:'၀ယ်ပြီးပစ္စည်း ငွေပြန်မအမ်းပါ။',
            //end sell product

            //start sell report
            invoices:'ဘောင်ချာများ',
            daily:'နေ့စဉ်',
            monthly:'လစဉ်',
            yearly:'နှစ်စဉ်',
            first_added:'အသစ်ထည့်သည်',
            in_shop_sell:'ဆိုင်တွင်းရောင်းချမှု',
            online_sell:'အွန်လိုင်းရောင်းချမှု',
            live_sell:'Live Sale ရောင်းချမှု',
            info_update:'ပြင်ဆင်ချက်',
            stock_update:'လက်ကျန်ပြင်ခြင်း',
            waste_update:'အပျက်အစီး',
            export:'ထုတ်မည်',
            sell_type:'ရောင်းချမှုပုံစံ',
            customer_name:'ဝယ်သူအမည်',
            sold_items:'ရောင်းချထားသောပစ္စည်းများ',
            unknown:'အမည်မသိ',
            control:'ထိန်းချုပ်မှု',
            count:'အရေအတွက်',
            details:'အသေးစိတ်',
            enter_voucher_id:'ဘောင်ချာအမှတ်ဖြင့်ရှာရန်',

            //end sell report

            //voucher details
            voucher_details:'ဘောင်ချာအသေးစိတ်',
            customer_info:'ဝယ်သူအချက်အလက်',
            total_credit:'စုစုပေါင်းကျန်ငွေ',
            print:'ဘောင်ချာထုတ်မည်',
            note:'မှတ်တမ်း',
            payment_history:'ငွေပေးချေမှုမှတ်တမ်း',
            select_payment_amount:'ငွေပေးချေမှုနည်းလမ်းရွေးပြီး ပေးချေသည့်ပမာဏထည့်ပါ။',
            refund:'ငွေပြန်အမ်းခြင်း',
            refund_manage:'ပြန်လဲပစ္စည်း ပြင်ဆင်ရန်',
            enter_refund_count:'ပြန်လဲမည့် အရေအတွက် ထည့်ပါ။',
            //end voucher details

            //start inventory
            product_management:'ကုန်ပစ္စည်း စီမံခန့်ခွဲမှု',
            categories_management:'အမျိုးအစား စီမံခန့်ခွဲမှု',
            live_management:'Live Sale စီမံခန့်ခွဲ့မှု',
            promotion_management:'ပရိုမိုးရှင်း စီမံခန့်ခွဲ့မှု',
            delivery_management:'Delivery စီမံခန့်ခွဲ့မှု',
            payment_management:'ငွေပေးချေစနစ် စီမံခန့်ခွဲ့မှု',
            cashier_management:'အရောင်းကောင်တာ စီမံခန့်ခွဲ့မှု',
            table_management:'စားပွဲ စီမံခန့်ခွဲမှု',
            tables:'စားပွဲများ',
            creat_new_table:'စားပွဲ အသစ်ထည့်ရန်',
            //end inventory

            //product create
            create_product:'အမျိုးအစားသစ် ထည့်ရန်',
            expire_date:'သက်တန်းကုန် ရက်စွဲ',
            //end product create

            //product details
            product_details:'ပစ္စည်းအသေးစိတ်',
            more_options:'လုပ်ဆောင်ချက်များ',
            edit:'ပြင်ဆင်ရန်',
            add_stock:'လက်ကျန်ထည့်ရန်',
            add_relation:'ဆက်စပ်ဇယားထည့်ရန်',
            waste:'အပျက်အစီး မှတ်ရန်',
            product_history:'ကုန်ပစ္စည်း မှတ်တမ်း',
            sellPrice:'ရောင်းဈေး',
            delete:'ဖျတ်မည်',

            weight:'အလေးချိန်',
            add_discount:'လျှော့ဈေး (1% မှ 100% အတွင်း)',
            unit:'ယူနစ်',
            relations_table:'ဆက်စပ်ဇယား',
            product_delete_warning:'ဖျတ်ရန်သေခြာပါသလား?ဤ ပစ္စည်းနှင့်ဆိုင်သော မှတ်တမ်းများပါ ဖျတ်ပါမည်။',
            purchase_lists:'အဝယ်စာရင်းများ',


            //end product details

            //products management
            products:'ကုန်ပစ္စည်းများ',
            photo:'ဓာတ်ပုံ',
            type:'အမျိုးအစား',
            buy_price:'ဝယ်စျေး',
            sell_price:'ရောင်းဈေး',
            kitchen:'မီးဖိုချောင်',
            brand:'အမှတ်တံဆိပ်',
            low_stock_products:'လက်ကျန်နည်းသောပစ္စည်းများ',
            expire_list:'သက်တန်းကုန်ဆုံးမှု',
            show_stock_customer:'Ecommerce Site တွင်ပစ္စည်းလက်ကျန်ပြမည်',
            //end products management

            //category management
            category:'အမျိုးအစား',
            create_category:'အမျိုးအစားအသစ်ထည့်ရန်',
            upload_image:'ဓာတ်ပုံထည့်ရန်',
            category_name:'အမျိုးအစားအမည်',
            category_lists:'အမျိုးအစားများ',
            cate_already_exist:'သည်အမျိုးအစားမှာ ရှိပြီသားဖြစ်သည်။',
            update_category:'အမျိုးအစား ပြင်မည်',
            delete_confirm:'ပယ်ဖျတ်ရန် သေခြာပါသလား?',
            deleted:'ပယ်ဖျတ်ပြီးပါပြီ။',
            updated:'ပြင်ဆင်ပြီးပါပြီ။',
            category_delete_confirm:'ဤအမျိုးအစားဖြင့် ပစ္စည်းများ သွင်းထားပါသည်။ ဖျတ်ရန်သေခြာပါသလား။',
            category_update_confirm:'ဤအမျိုးအစားဖြင့် ပစ္စည်းများ သွင်းထားပါသည်။ ပြင်ရန်သေခြာပါသလား။',
            enter_category:'အမျိုးအစားအမည် ထည့်ပါ။',
            //end category management

            //refund
            available:'ရောင်းပြီး',
            refund_count:'ပြန်လဲမည့်ပမာဏ',

            //Branches
            branches:'ဆိုင်ခွဲများ',



            //Home
            welcome: 'ကြိိုဆိုပါတယ်။',
            welcome2:'Shopping Mall ကိုလေ့လာကြည့်ပါ။',
            nav_home:'ပင်မစာမျက်နာ',
            nav_about:'အကြောင်းအရာ',
            nav_contact:'ဆက်သွယ်ရန်',
            nav_signIn:'အကောင့်ဝင်ရန်',
            search_cities:'မြို့ရွေးချယ်ပါ။',

            //Shop
            shop_page_title:'ယခုလက်ရှိမြို့',
            shop_page_choose:'မြို့ရွေးရန်',
            shop_page_categories:'အမျိုးအစားများ',
            shop_page_showAll:'အားလုံးကြည့်ရန်',
            shop_page_trending:'ရေပန်းစားနေသော ဆိုင်များ',
            shop_page_promotion:'ပရိုမိုးရှင်းရှိသော ဆိုင်များ ',
            shop_page_recommend:'အကြံပြုထားသော ဆိုင်များ',

            //Shop Info, card
            search:'ရှာဖွေရန်',
            shop_profile:'ဆိုင်အချက်အလက်',
            shop_products:'ထုတ်ကုန်များ',
            all:'အားလုံး',
            seeMore:'ပိုမိုကြည့်ရှုရန်',
            add_to_cart: 'စျေးခြင်းထဲသို့ထည့်ပါ',
            description:'ဖော်ပြချက်',
            size:'အရွယ်အစား',
            color:'အရောင်',
            out_of_stock:'ပစ္စည်းပြတ်နေသည်',
            item_available:'ခု ရှိသည်။',
            best_seller:'ရောင်းအားအကောင်းဆုံး ပစ္စည်းများ',

            //User Profile
            logout:'ထွက်မည်',
            update:'ပြင်ဆင်မည်',
            profile:'ပရိုဖိုင်',
            orders:'ပုံမှန် မှာယူထားသောပစ္စည်းများ',
            live_orders:'Live အော်ဒါများ',
            view_details:'အသေးစိတ်ကြည့်ရန်',
            pending:'မှာထားဆဲ',
            confirm:'အတည်ပြုသည်',
            confirmed:'အတည်ပြုပြီး',
            paid:'ပေးချေပြီး',
            cancel:'မလုပ်ဆောင်ပါ',
            canceled:'ပယ်ဖျတ်ပြီး',
            order_id:'အော်ဒါနံပါတ်',
            total_price:'စုစုပေါင်း',


            phone:'ဖုန်:',
            email:'အီးမေးလ်',
            name:'နာမည်',
            address:'လိပ်စာ',
            password:'လျှို့ဝှက် နံပါတ်',
            signin:'အကောင့်ဝင်မည်',
            register:'မှတ်ပုံတင်မည်',
            continue_with:'အခြားနည်းလမ်း',

            order_details:'အော်ဒါ အသေးစိတ်',
            date:'ရက်စွဲ',
            delivery_method:'အော်ဒါပို့ဆောင်မှု နည်းလမ်း',
            payment_method:'ငွေပေးချေမှု နည်းလမ်း',
            choose_payment:'ငွေပေးချေမှု နည်းလမ်း ရွေးချယ်ပါ',
            choose_delivery:'အော်ဒါပို့ဆောင်မှု နည်းလမ်း ရွေးချယ်ပါ',
            account_no:'အကောင့် နံပါတ်',
            upload_transaction:'ငွေလွှဲပြေစာ တင်ပါ',
            send:'ပို့မည်',
            select_city:'မြို့ရွေးချယ်ပါ',
            other:'အခြား',
            save:'သိမ်းမည်',


            cart_items:'ရွေးချယ်ထားသောပစ္စည်းများ',
            cost:'ကျသင့်ငွေ',
            update_again: 'ပြင်ဆင်မည်',
            next:'ရှေ့သို့',
            these_rur_info:'သင့်ရဲ့လိပ်စာမှန်ပါသလား။',
            update_info:'သင့်အချက်အလက်များကို ပြင်ပါ။',
            update_info_warning:'သင့်လိပ်စာကို ထည့်ပါ။',
            promo_code:'ပရိုမိုးရှင်းကုဒ်နံပါတ်',
            previous:'နောက်သို့',
            order_now:'မှာမည်',
            check:'စစ်ဆေးပါ',
            check_order:'အော်ဒါ စစ်ဆေးမည်။',
            order_success:'ဂုဏ်ယူပါတယ်!! သင့်အမှာစာသည် ဆိုင်ရှင်ထံသို့ အောင်မြင်စွာရောက်ရှိသွားပါပြီ။',
            sry_cant_promo:'ဝမ်းနည်းပါတယ်! ဤပရိုမိုကုဒ်ကို သင်အသုံးမပြုနိုင်ပါ။',
            sry_purchase_least:'ဝမ်းနည်းပါတယ်! အနည်းဆုံးဝယ်ယူရမည့် ပမာဏမှာ',
            sry_code_expire:"ဝမ်းနည်းပါတယ်!သင့်ကုဒ် သက်တမ်းကုန်သွားပါပြီ။",
            sry_code_already:"ဝမ်းနည်းပါတယ်! သင့်အကောင့်သည် ပရိုမိုးရှင်းကုဒ်ကို အသုံးပြုပြီးဖြစ်သည်။",
            sry_promo_limit:"ပရိုမိုးရှင်းကန့်သတ်ချက် ပြည့်သွားလို့ တောင်းပန်ပါတယ်။",

            //Live Cart
            message:'အမှာစာ',
            live_order_cant:"ဝမ်းနည်းပါတယ်၊ သင်အခု မှာလို့မရပါ။ နောက်ဆက်ရှင်တွင် ထပ်စမ်းကြည့်ပါ။",
            buy_now:'ဝယ်မည်',
            live_sale_product:'ရောင်းချနေသော ပစ္စည်းများ',

            //Dialog,
            yes:'အတည်ပြုသည်',
            no:'မလုပ်ဆောင်ပါ',
            close:'ပိတ်ပါ',

            //shop card warning
            already_selected:'ရွေးထားပြီးသားဖြစ်နေပါတယ်။',
            do_u_remove_item:'ယခင်ဆိုင်မှာ ရွေးထားသော ပစ္စည်းများကို ဖယ်ရှားလိုပါသလား။',

            hello:"မင်္ဂလာပါ",
            wct_flash:'Flash Mall မှကြိုဆိုပါတယ်။',
            enter_phone:'သင့်ဖုန်းနံပါတ်ကို ထည့်ပါ။',
            get_otp:'OTP ကုဒ်ရယူမည်',
            enter_code:'OTP ကုဒ်နံပါတ် ရိုက်ထည့်ပါ။',
            verify:'စစ်ဆေးပါ',
            resend:'OTP ကုဒ် ပြန်ပို့ပါ',

            mail_already:'မေးလ်ကို အသုံးပြုထားပြီးဖြစ်သည်။!',
            not_register:"သင်သည် ဤမေးလ်ဖြင့် စာရင်းသွင်းထားခြင်း မရှိသေးပါ။",
            otp_sent:'OTP ကို SMS မှတဆင့် သင့်ဖုန်းဆီသို့ ပို့လိုက်ပါပြီ။ ခဏစောင့်ပါ။',
            invalid_phone:'ဖုန်းနံပါတ် မမှန်ကန်ပါ။',
            invalid_code:'Invalid Verification Code!',
            invalid_otp:'မမှန်ကန်သော အတည်ပြုကုဒ်။ !',
            veri_success:'အတည်ပြုခြင်း အောင်မြင်သည်။',
            fill_name:'အသုံးပြုသူအမည်ဖြည့်ပါ။',
            fill_email:'ကျေးဇူးပြု၍ အီးမေးလ်ဖြည့်ပါ။',
            fill_address:'လိပ်စာဖြည့်ပါ။',

            apartment_info: 'အခန်း အချက်အလက်',
            apartments: "အခန်းများ",
            reservation: "ကြိုတင်စာရင်းသွင်းခြင်း",
            serve_now: "စတင်မည်",
            table_count:"စာပွဲအရေအတွက်",
            create_booking:"ဘိုကင်ယူမည်",
            reject:'ပယ်ဖျတ်မည်',
            booking_alert:'ဘိုကင် သတိပေးချက်',
            status:'အခြေအနေ',
            booking_date:'ဘိုကင်ရက်စွဲ',
            current_staying:'လက်ရှိတည်းခိုနေမှု',
            create_apartment:"အခန်းသစ်ထည့်ရန်",

            check_booking: "စာရင်းကြည့်မည်",
            update_reservation: "ကြိုတင်စာရင်း ပြုပြင်ခြင်း",
            create_reservation:"ကြိုတင်စာရင်း အသစ်ထည့်ခြင်း",
            reservation_histories: "ကြိုတင်စာရင်း မှတ်တမ်းများ",
            extra_bed_fee:"ကုတင်အပိုယူခ",
            room_price:"အခန်းခ",
            reserved:"စာရင်းသွင်းထားပြီး",
            reserve:"စာရင်းသွင်းမည်",
            check_in:"၀င်ရောက်မည်",

            checkout:"အခန်းမှထွက်မည်",
            checkin_detail:"အခန်း၀င်ရောက်မှု အသေးစိတ်",
            menu:'မီနူး',
            extra_bed:'ကုတင်အပို',
            clear_all:'ပယ်ဖျတ်မည်',
            ordered_foods:'မှာယူထားသောမီနူးများ',
            reserved_dates:'စားရင်းသွင်းထားသောနေ့များ',
            guest_info:'ဧည့်သည်အချက်အလက်',
            reservation_list:'အခန်းစာရင်းများ',
            start_date:'စရက်',
            end_date:'ဆုံးရက်',
            create_ticket:'လက်မှတ်ဖြတ်ရန်',
            create_party:'ပါတီလက်မှတ်ဖြတ်ရန်',
            swimming_pool_report:'ရေကူးကန် စာရင်း',
            ticket_id:'လက်မှတ် နံပါတ်',
            people_count:'လူဦးရေ',


            select_date_by_order:"ရက်များကို အစဥ်လိုက်််ရွေးပေးပါ။",
            create_new_aprtment:"အခန်းသစ် ထည့်ရန်",
            family_count:"မိသားစု ဦးရေ",
            room_type:"အခန်းအမျိုးအစား",
            room:"အခန်း",
            adult_count:'လူကြီး အရေအတွက် ',
            infant_count:'ကလေး အရေအတွက်',
            paid_amount:'ကြိုတင်သွင်းငွေ',
            reserved_date:'စာရင်းသွင်းသည့်ရက်',
            sure_checkin:'အခန်းသို့ စတင် တည်းခိုမည်?',

            staffs_management:"၀န်ထမ်း စီမံခန့်ခွဲမှု",
            staffs:"၀န်ထမ်းများ",
            add_staff:"၀န်ထမ်းသစ် ထည့်မည်",
            department:'ဌာန',
            dob:'မွေးနေ့',
            basic_salary:'အခြေခံလစာ',
            take_attendance:'အလုပ်၀င်ချိန် ထည့်မည်',
            staff_attendance:'၀န်ထမ်းများ အလုပ်၀င်ချိန်စာရင်း',
            start_time:'စတင်ချိန်',
            end_time:'ပြီးဆုံးချိန်',
            working_hours:"အလုပ်လုပ်ချိန်",
            staff_id:"၀န်ထမ်း အမှတ်"

        }
    },
    chn:{
        message: {

            //owner
            //home
            home_page :'Home Page',
            sell_product:'Sell Products',
            sell_report:'Sell Report',
            inventory:'Inventory',
            financial_report:'Financial Report',
            general_settings:'General Settings',
            inventory_control:'Inventory Control',
            setting:'Setting',
            warehouse:'Ware House',
            online_orders:'Online Orders',

            //Sell Products
            barcode:'Barcode',
            itemCode:'Item Code',
            itemName:'Item Name',
            search_by_barcode:'Search by barcode',
            stock:'Stock',
            selected_items:'Selected Items',
            submit_sales:'Submit Sales',
            sale_setup:'Sale Setup',
            discount:'Discount',
            by_price:'By Price',
            by_rate:'By Rate',
            reset:'Reset',
            enter_discount_ks:'Enter discount (Ks)',
            enter_discount_rate:'Enter discount ( 1-100%)',
            enter_pay_amount:'Enter pay amount',
            please_choose_payment:'Please choose payment method',
            pay_amount:'Pay amount',
            add:'Add',
            customers:'Customers',
            //voucher
            list_view:'List Views',
            table_view:'Table Views',
            voucher_no:'Voucher No',
            items:'Items',
            qty:'Qty',
            price:'Price',
            amount:'Amount',
            total:'Total',
            tax:'Tax',
            net_amount:'Net Amount',
            cash_received:'Received',
            change:'Change',
            credit:'Credit',
            sell_now:'Sell now',
            thank:'Thank You',
            restrict_refund:'Refund does not allow',
            //end sell products

            //start sell report
            invoices:'Invoices',
            daily:'Daily',
            monthly:'Monthly',
            yearly:'Yearly',
            first_added:'Added',
            in_shop_sell:'In Shop Sell',
            online_sell:'Online Sell',
            live_sell:'Live Sell',
            info_update:'Info Update',
            stock_update:'Stock Update',
            waste_update:'Waste',
            export:'Export',
            sell_type:'Sell Type',
            customer_name:'Customer Name',
            sold_items:'Sold Items',
            control:'Control',
            count:'Count',
            details:'Details',
            enter_vocher_id:'Enter Voucher Id',
            //end sell report

            //start inventory
            product_management:'Products Management',
            categories_management:'Categories Management',
            live_management:'Live Sale Management',
            promotion_management:'Promotion Management',
            delivery_management:'Delivery Management',
            payment_management:'Payment Management',
            cashier_management:'Cashier Management',
            table_management:'Table Management',
            tables:'Tables',
            creat_new_table:'Create new table',

            //end inventory
            //product create
            create_product:'Add New Product',
            expire_date:'Expire Date',
            //end product create

            //products management
            products:'Products',
            item_code:'Item Code',
            photo:'Photo',
            type:'Type',
            buy_price:'Buy Price',
            sell_price:'Sell Price',
            kitchen:'Kitchen',
            brand:'Brand',
            low_stock_products:'Low Stock Products',
            expire_list:'Expire Lists',
            show_stock_customer:'Show Stock to Customers',

            //end products management

            //category management
            category:'Category',
            create_category:'Create New Category',
            upload_image:'Upload Image',
            category_name:'Category name',
            category_lists:'Category Lists',
            cate_already_exist:'Category name is already added.',
            update_category:'Update Category',
            delete_confirm:'Are you sure to delete?',
            deleted:'Deleted.',
            updated:'Updated',
            category_delete_confirm:'This is category is used by your products. Are you sure to delete?',
            category_update_confirm:'This is category is used by your products. Are you sure to update?',
            enter_category:'Enter Category Name',
            //end category management

            //voucher details
            voucher_details:'Voucher Details',
            customer_info:'Customer Info',
            total_credit:'Total Credit',
            print:'Print',
            note:'',
            payment_history:'Payment History',
            select_payment_amount:'Please select payment method and fill amount',
            refund:'Refund',
            refund_manage:'Manage Refund Items',
            enter_refund_count:'Enter Refund Count',
            //end voucher details

            //product detail
            product_details:'Product Details',
            more_options:'More Options',
            edit:'Edit',
            add_stock:'Add Stock',
            add_relation:'Add Relation',
            waste:'Waste',
            product_history:'Product History',
            sellPrice:'Sell Price',
            delete:'Delete',

            weight:'Weight',
            add_discount:'Add Discount (1-100%)',
            unit:'Unit',
            relations_table:'Relation Tables',
            product_delete_warning:'Are you sure to delete? Records of this product will be permanently deleted.',
            purchase_lists:'Purchase Lists',

            //end product detail

            //Home
            welcome: '欢迎',
            welcome2:'探索我们的购物中心',
            nav_home:'家',
            nav_about:'关于我们',
            nav_contact:'联系我们',
            nav_signIn:'登入',
            search_cities:'搜索城市',

            //Shops
            shop_page_title:'当前城市',
            shop_page_choose:'选择城市',
            shop_page_categories:'类别',
            shop_page_showAll:'显示所有项目',
            shop_page_trending:'热门店铺',
            shop_page_promotion:'促销活动',
            shop_page_recommend:'推荐店铺',

            //All Shops, shopInfo, card
            search:'搜索',
            shop_profile:'店铺简介',
            shop_products:'产品',
            all:'全部',
            seeMore:'看更多',
            add_to_cart:'添加到购物车',
            description:'描述',
            size:'尺寸',
            color:'颜色',
            out_of_stock:'缺货',
            item_available:'件可用',
            best_seller:'畅销商品',


            //User Profile
            logout:'登出',
            update:'更新',
            profile:'轮廓',
            orders:'订单',
            live_orders:'Live 订单',
            view_details:'查看详情',
            pending:'待办的',
            confirm:'确认的',
            paid:"付款",
            cancel:'取消',
            canceled:'Canceled',
            order_id:'订单号',
            total_price:'总计',

            phone:'电话号码',
            email:'电子邮件',
            name:'姓名',
            address:'地址',
            password:'密码',
            signin:'登入',
            register:'登记',
            continue_with:'其他方法',

            order_details:'订单详细信息',
            date:'日期',
            delivery_method:'交货方式',
            payment_method:'立即付款',
            choose_payment:'选择付款方式',
            choose_delivery:'选择交付方式',
            account_no:'帐号',
            upload_transaction:'上传交易照片',
            send:'发送',
            select_city:'选择城市',
            other:'其他',
            save:'节省',

            //Cart
            cart_items:'您的物品',
            cost:'总价',
            update_again:'再次更新',
            update_info:'更新您的信息',
            update_info_warning:'请更新您的电话号码或地址！',
            next:'下一个',
            these_rur_info:'这些是您当前的正确信息吗？',
            promo_code:'促销代码',
            previous:'以前的',
            order_now:'现在下单',
            check:'查看',
            check_order:'检查订单',
            order_success:'恭喜！您的订单已成功到达店主。',
            sry_cant_promo:'抱歉！您不能使用此促销代码',
            sry_purchase_least:'对不起！您至少要购买',
            sry_code_expire:"抱歉！您的代码已过期。",
            sry_code_already:"抱歉，您的帐户已使用促销代码！",
            sry_promo_limit:"对不起，促销限额已满！",

            //Live, cart
            message:'订单信',
            live_order_cant:"抱歉，您现在不能订购！请在下一个会话中重试。",
            buy_now:'立即购买',
            live_sale_product:'现场销售产品',

            //shop card warning
            already_selected:'已经选择',
            do_u_remove_item:'您想从以前的商店中删除商品吗？',

            hello:"你好",
            wct_flash:'欢迎来到 Flash Mall',
            mail_already:'邮件已注册！',
            enter_phone:'输入你的电话号码',
            get_otp:'获取 OTP',
            enter_code:'输入您的 OTP 代码',
            verify:'核实',
            resend:'重新发送 OTP 代码',

            not_register:"您尚未注册此邮件。",
            otp_sen:'OTP已通过短信发送到您的手机，请稍等',
            invalid_phone:'无效的电话号码！',
            invalid_code:'无效验证码！',
            invalid_otp:'无效的 OTP 格式！',
            veri_success:'验证成功',
            fill_name:'请填写用户名！',
            fill_email:'请填写邮箱！',
            fill_address:'请填写地址！',

            apartment_info: 'Apartment Information',
            apartments: "Apartments",
            reservation: "Reservation",
            table_count:'Table Count',
            create_booking:'Create Booking',
            serve_now: "Serve Now",
            reject:'reject',
            booking_alert:'Booking Alert',
            status:'Status',
            booking_date:'Booking Date',

            check_booking: "Check Booking",
            update_reservation: "Update Reservation",
            create_reservation:"Create Reservation",
            reservation_histories: "Reservation Histories",
            extra_bed_fee:"Extra Bed Fee",
            room_price:"Room Price",
            reserved: "Reserved",
            checkout:"Check out",
            checkin_detail:"Checkin Detail",
            menu:'Menu',
            extra_bed:'Extra bed',
            clear_all:'Clear All',
            ordered_foods:'Ordered Foods',
            reserved_dates:'Reserved Dates',
            guest_info:'Guest Info',
            reservation_list:'Reservation List',
            start_date:'Start Date',
            end_date:'End Date',
            create_ticket:'Ticket',
            create_party:'Party Ticket',
            swimming_pool_report:'Swimming Pool Report',
            ticket_id:'Ticket ID',
            people_count:'People Count',

            select_date_by_order:"Please Select date in order",
            create_new_aprtment:"Create New Apartment",
            family_count:"Family Count",
            room_type:"Room Type",
            room:"Room",
            adult_count:'Adult Count',
            infant_count:'Infant Count',
            paid_amount:'Paid Amount',
            reserved_date:'Reserved Date',
            sure_checkin:'Are you sure to check in?',

            staffs_management:"Staffs Management",
            staffs:"Staffs",
            add_staff:"Add Staff",
            department:'Department',
            dob:'Date of  birth',
            basic_salary:'Basice Salary',
            take_attendance:'Takce Attendance',
            staff_attendance:'Staff Attendance',
            start_time:'Start Time',
            end_time:'End Time',
            working_hours:"Working Hours",
            staff_id:"Staff ID"

            
        }
    }
}