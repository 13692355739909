<template>
  <section class="bar">
    <div class="shade"></div>

    <div class="center">
      <h2 class="title">360&deg; BAR</h2>
      <router-link class="booking-button" to="/online-reservation/bar"
        >BOOK NOW</router-link
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "Bar",
};
</script>

<style scoped>
.bar {
  scroll-snap-align: start;
  background-image: url(../../assets/images/bar.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shade {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.center {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  color: var(--primary);
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 0.35rem;
}
@media (max-width: 1024px) {
  .title {
    font-size: 3rem;
  }
}
.booking-button {
  padding-block: 0.5rem;
  width: 100%;
  max-width: 12rem;
  background-color: transparent;
  border: solid 3px var(--primary);
  border-radius: 100vw !important;
  color: var(--primary);
  letter-spacing: 0.075em;
  font-weight: 600;
  transition: all ease 200ms;
  text-align: center;
  text-decoration: none;
}
.booking-button:hover {
  border: solid 3px white;
  transform: none;
  color: white;
  background-color: var(--primary);
}
</style>
