<template>
  <div style="position: relative; width: 100%; max-width: 25rem">
    <div class="container">
      <div v-for="(table, index) in filterTables" :key="index" class="table">
        <p>Table - {{ table.name }} is ready to print</p>
        <button class="close" @click="() => closeReady(table.id)">
          <span class="x">x</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import db from "../config/FirebaseInit";

export default {
  computed: {
    owner() {
      return this.$store.getters.getShopOwner;
    },
    tableCategories() {
      return this.$store.getters.getTableCategories;
    },
    cashierAcc() {
      return this.$store.getters.getCashierAcc;
    },
    tables() {
      return this.$store.getters.getAllTables;
    },

    filterTables() {
      if (this.tables) {
        return this.tables
          .filter((table) => {
            return (
              ["Restaurant", "Bar"].includes(table.category) &&
              table.readyToPrint === "yes"
            );
          })
          .slice(2, 4);
      } else return [];
    },
  },
  methods: {
    closeReady(id) {
      db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection("tables")
        .doc(id)
        .update({ readyToPrint: "no" });
    },
  },
};
</script>
<!-- && table.readyToPrint === 'yes' -->
<style scoped>
.container {
  position: absolute;
  z-index: 10000;
  left: 5rem;
  top: 1rem;
  width: 100%;
}
.table {
  width: 100%;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 0.75rem;
  height: 2.5rem;
  color: white;
  margin-top: -0.5rem;
}
.close {
  border: none;
  color: red;
  font-size: 1.25rem;
  border: solid 1px red;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  margin-top: -0.25rem;
}
p {
  margin-top: 0.75rem;
}
.x {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
</style>
