<template>
  <div class="container">
    <div class="row mt-5 pt-5">
      <div class="category-box">
        <div
          class="text-start d-flex flex-wrap gap-4 list-unstyled border-0 justify-content-center items-center"
        >
          <button
            class="d-inline-block mx-1 category-list"
            @click="selectCate('All')"
            :class="selectedCategory === 'All' ? 'category-list-active' : ''"
          >
            All
          </button>
          <button
            v-for="(category, index) in filterCategories"
            :key="index"
            class="category-list d-inline-block"
            :class="
              selectedCategory === category.name ? 'category-list-active' : ''
            "
            @click="selectCate(category.name)"
          >
            {{ category.name }}
          </button>
        </div>
      </div>
      <div class="product-box">
        <div class="products-container">
          <div
            class="product-card text-center"
            v-for="(product, index) in filterProducts"
            :key="index"
          >
            <div class="">
              <img
                :src="product.images[0]"
                v-if="product.images.length > 0"
                style="
                  width: 80%;
                  aspect-ratio: 1 / 1;
                  border-radius: var(--border-radius) !important;
                "
                alt=""
              />
              <img
                src="../../public/logo.png"
                v-else
                style="
                  opacity: 0.3;
                  width: 80%;
                  aspect-ratio: 1 / 1;
                  border-radius: var(--border-radius) !important;
                "
                alt=""
              />
            </div>
            <div
              class="ms-2 p-1 text-start"
              style="border-radius: var(--border-radius)"
            >
              <div class="d-flex text-start flex-column">
                <div
                  class="d-flex justify-content-between text-wrap align-items-start"
                  style="height: 25px"
                >
                  <span class="fw-bold" style="color: var(--text-color)">{{
                    product.itemName
                  }}</span>
                </div>
                <!--                    <span class="mt-3" style="font-size: 12px;color:var(&#45;&#45;text-color-light);">{{product.barcode}}</span>-->
                <span class="mt-3" style="color: var(--primary)"
                  >{{ parseInt(product.sellPrice).toLocaleString() }} Ks</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedCategory: "All",
    };
  },
  computed: {
    allProducts() {
      return this.$store.getters.getAllProducts;
    },
    productCategories() {
      return this.$store.getters.getProductCategories;
    },
    filterCategories() {
      return this.productCategories.filter((cate) => {
        return cate.name !== "All" && cate?.counter === "Restaurant";
      });
    },
    filterProducts() {
      let filterProduct = [];

      if (this.selectedCategory !== "All") {
        filterProduct = this.allProducts.filter(
          (pro) => pro.type === this.selectedCategory
        );
        return filterProduct;
      } else {
        return this.allProducts?.filter(
          (pro) => pro.kitchenType === "Restaurant"
        );
      }
    },
  },
  methods: {
    selectCate(name) {
      this.selectedCategory = name;
    },
  },
};
</script>

<style scoped>
.category-list {
  border: none;
  background-color: transparent;
  background-color: #eee;
  border-radius: 5rem;
  padding-inline: 1rem;
}
.category-list-active {
  color: white;
  background-color: var(--primary);
}

.products-container {
  width: 100%;
  margin-top: 2rem;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.product-card {
  width: 100%;
  max-width: 250px;
  padding-inline: 1rem;
  aspect-ratio: 2 / 2;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s;
  padding-top: 5px;
  border-radius: var(--border-radius) !important;
  background-color: var(--white);
  box-shadow: 0 0 50px -20px rgba(204, 204, 204, 0.8);
}

.product-card:hover {
  color: var(--text-color) !important;
  /* background-color: var(--secondary) !important; */
}
</style>
