<template>
  <main>
    <section class="carousel">
      <Carousel :items-to-show="1" :wrap-around="true" autoplay="5000">
        <Slide v-for="(slide, index) in images" :key="index">
          <img class="image" :src="slide" alt="" />
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>
      <div class="socials">
        <a
          href="https://web.facebook.com/360tgi"
          target="_blank"
          rel="noreferrer"
          ><i style="font-size: 2rem; color: white" class="fab fa-facebook"></i
        ></a>
      </div>
    </section>
    <Restaurant />
    <Pools />
    <Bar />
    <Coffee />
    <Apartment />
  </main>
</template>

<script setup>
import Restaurant from "../components/Home/Restaurant.vue";
import Bar from "../components/Home/Bar.vue";
import Pools from "../components/Home/SwimmingPool.vue";
import Coffee from "../components/Home/CoffeeShop.vue";
import Apartment from "../components/Home/Apartment.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import image1 from "../assets/images/carousel1.jpg";
import image2 from "../assets/images/carousel2.jpg";
import image3 from "../assets/images/carousel3.jpg";
const images = [image1, image2, image3];
</script>

<style scoped>
main {
  scroll-snap-type: y mandatory;
}
.carousel {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: sticky;
  top: 0;
}

.image {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}
.socials {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
}
</style>
